import axios from "axios";
import { config } from "./config.js";

// config before deployment
// Keeping a safe copy

// const axiosApi =
//   config.production === false
//     ? axios.create({
//         baseURL: `http://localhost:3084`,
//       })
//     : axios.create({
//         baseURL: `http://54.37.64.178:3084`,
//       });

const baseUrls = {
  dev_local: "http://localhost:3084",
  dev_remote: "http://54.37.64.178:3084",
  production: "https://sav.backend.viwonepureapp.com",
};

let config_mode;
Object.keys(config).map((mode) => {
  config_mode = config[mode] == true ? mode : config_mode;
});
const axiosApi = axios.create({
  baseURL: baseUrls[config_mode],
});

export default axiosApi;
