import React from "react";

const Breadcrumbs = React.lazy(() => import("./views/Base/Breadcrumbs"));
const Cards = React.lazy(() => import("./views/Base/Cards"));
const Carousels = React.lazy(() => import("./views/Base/Carousels"));
const Collapses = React.lazy(() => import("./views/Base/Collapses"));
const Dropdowns = React.lazy(() => import("./views/Base/Dropdowns"));
const Forms = React.lazy(() => import("./views/Base/Forms"));
const Jumbotrons = React.lazy(() => import("./views/Base/Jumbotrons"));
const ListGroups = React.lazy(() => import("./views/Base/ListGroups"));
const Navbars = React.lazy(() => import("./views/Base/Navbars"));
const Navs = React.lazy(() => import("./views/Base/Navs"));
const Paginations = React.lazy(() => import("./views/Base/Paginations"));
const Popovers = React.lazy(() => import("./views/Base/Popovers"));
const ProgressBar = React.lazy(() => import("./views/Base/ProgressBar"));
const Switches = React.lazy(() => import("./views/Base/Switches"));
const Tables = React.lazy(() => import("./views/Base/Tables"));
const Tabs = React.lazy(() => import("./views/Base/Tabs"));
const Tooltips = React.lazy(() => import("./views/Base/Tooltips"));
const BrandButtons = React.lazy(() => import("./views/Buttons/BrandButtons"));
const ButtonDropdowns = React.lazy(() =>
  import("./views/Buttons/ButtonDropdowns")
);
const ButtonGroups = React.lazy(() => import("./views/Buttons/ButtonGroups"));
const Buttons = React.lazy(() => import("./views/Buttons/Buttons"));
const Charts = React.lazy(() => import("./views/Charts"));
const Dashboard = React.lazy(() => import("./views/Dashboard"));
const CoreUIIcons = React.lazy(() => import("./views/Icons/CoreUIIcons"));
const Flags = React.lazy(() => import("./views/Icons/Flags"));
const FontAwesome = React.lazy(() => import("./views/Icons/FontAwesome"));
const SimpleLineIcons = React.lazy(() =>
  import("./views/Icons/SimpleLineIcons")
);
const Alerts = React.lazy(() => import("./views/Notifications/Alerts"));
const Badges = React.lazy(() => import("./views/Notifications/Badges"));
const Modals = React.lazy(() => import("./views/Notifications/Modals"));
const Colors = React.lazy(() => import("./views/Theme/Colors"));
const Typography = React.lazy(() => import("./views/Theme/Typography"));
const Widgets = React.lazy(() => import("./views/Widgets/Widgets"));
const Users = React.lazy(() => import("./views/Users/Users"));
const Produits = React.lazy(() => import("./views/Produits/Produits"));
const Composants = React.lazy(() => import("./views/Composants/Composants"));
const ComposantDetails = React.lazy(() =>
  import("./views/Composants/ComposantDetails")
);
const User = React.lazy(() => import("./views/Users/User"));
const OperationSavDetails = React.lazy(() =>
  import("./views/Dashboard/OperationSavDetails")
);
const MesOperationsSav = React.lazy(() =>
  import("./views/Dashboard/MesOperationSav")
);
const OperationsSavEnAttentePourTechnicien = React.lazy(() =>
  import("./views/Dashboard/OperationsSavEnAttentePourTechnicien")
);
const OperationSavCreation = React.lazy(() =>
  import("./views/Dashboard/OperationSavCreation")
);
const HistoriqueDesOperationSav = React.lazy(() =>
  import("./views/Dashboard/HistoriqueDesOperationSav")
);
const ProduitDetails = React.lazy(() =>
  import("./views/Produits/ProduitDetails")
);
const Clients = React.lazy(() => import("./views/Clients/Clients"));
const Client = React.lazy(() => import("./views/Clients/Client"));
const DashboardAdministrateur = React.lazy(() =>
  import("./views/Dashboard/DashboardAdministrateur")
);
const Settings = React.lazy(() => import("./views/User/Settings"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
// Admin dashboard
const routes = [
  { path: "/home", exact: true, name: "Accueil" },
  {
    path: "/home/configurationCompte",
    name: "Configuration",
    component: Settings,
  },
  { path: "/home/listeOperations", name: "Dashboard", component: Dashboard },
  { path: "/home/theme", exact: true, name: "Theme", component: Colors },
  { path: "/home/theme/colors", name: "Colors", component: Colors },
  { path: "/home/theme/typography", name: "Typography", component: Typography },
  { path: "/home/base", exact: true, name: "Base", component: Cards },
  { path: "/home/base/cards", name: "Cards", component: Cards },
  { path: "/home/base/forms", name: "Forms", component: Forms },
  { path: "/home/base/switches", name: "Switches", component: Switches },
  { path: "/home/base/tables", name: "Tables", component: Tables },
  { path: "/home/base/tabs", name: "Tabs", component: Tabs },
  {
    path: "/home/base/breadcrumbs",
    name: "Breadcrumbs",
    component: Breadcrumbs,
  },
  { path: "/home/base/carousels", name: "Carousel", component: Carousels },
  { path: "/home/base/collapses", name: "Collapse", component: Collapses },
  { path: "/home/base/dropdowns", name: "Dropdowns", component: Dropdowns },
  { path: "/home/base/jumbotrons", name: "Jumbotrons", component: Jumbotrons },
  {
    path: "/home/base/list-groups",
    name: "List Groups",
    component: ListGroups,
  },
  { path: "/home/base/navbars", name: "Navbars", component: Navbars },
  { path: "/home/base/navs", name: "Navs", component: Navs },
  {
    path: "/home/base/paginations",
    name: "Paginations",
    component: Paginations,
  },
  { path: "/home/base/popovers", name: "Popovers", component: Popovers },
  {
    path: "/home/base/progress-bar",
    name: "Progress Bar",
    component: ProgressBar,
  },
  { path: "/home/base/tooltips", name: "Tooltips", component: Tooltips },
  { path: "/home/buttons", exact: true, name: "Buttons", component: Buttons },
  { path: "/home/buttons/buttons", name: "Buttons", component: Buttons },
  {
    path: "/home/buttons/button-dropdowns",
    name: "Button Dropdowns",
    component: ButtonDropdowns,
  },
  {
    path: "/home/buttons/button-groups",
    name: "Button Groups",
    component: ButtonGroups,
  },
  {
    path: "/home/buttons/brand-buttons",
    name: "Brand Buttons",
    component: BrandButtons,
  },
  { path: "/home/icons", exact: true, name: "Icons", component: CoreUIIcons },
  {
    path: "/home/icons/coreui-icons",
    name: "CoreUI Icons",
    component: CoreUIIcons,
  },
  { path: "/home/icons/flags", name: "Flags", component: Flags },
  {
    path: "/home/icons/font-awesome",
    name: "Font Awesome",
    component: FontAwesome,
  },
  {
    path: "/home/icons/simple-line-icons",
    name: "Simple Line Icons",
    component: SimpleLineIcons,
  },
  {
    path: "/home/notifications",
    exact: true,
    name: "Notifications",
    component: Alerts,
  },
  { path: "/home/notifications/alerts", name: "Alerts", component: Alerts },
  { path: "/home/notifications/badges", name: "Badges", component: Badges },
  { path: "/home/notifications/modals", name: "Modals", component: Modals },
  { path: "/home/widgets", name: "Widgets", component: Widgets },
  { path: "/home/charts", name: "Charts", component: Charts },
  { path: "/home/users", exact: true, name: "Utilisateurs", component: Users },
  { path: "/home/produits", name: "Produits", component: Produits },
  { path: "/home/composants", name: "Composants", component: Composants },
  {
    path: "/home/composant/:id",
    exact: true,
    name: "Détails composant",
    component: ComposantDetails,
  },
  {
    path: "/home/users/:id",
    exact: true,
    name: "Détails utilisateur",
    component: User,
  },
  {
    path: "/home/operationSav/:id",
    exact: true,
    name: "Opération SAV",
    component: OperationSavDetails,
  },
  {
    path: "/home/operationsSavEnAttentePourTechnicien",
    exact: true,
    name: "Mes opérations SAV",
    component: OperationsSavEnAttentePourTechnicien,
  },
  {
    path: "/home/mesOperationsSav",
    exact: true,
    name: "Mes opérations SAV",
    component: MesOperationsSav,
  },
  {
    path: "/home/historiqueDesOperationsSAv",
    exact: true,
    name: "Historique des opérations SAV",
    component: HistoriqueDesOperationSav,
  },
  {
    path: "/home/operationSavCreation",
    exact: true,
    name: "Créer une opération SAV",
    component: OperationSavCreation,
  },
  {
    path: "/home/produit/:id",
    exact: true,
    name: "Produit",
    component: ProduitDetails,
  },
  {
    path: "/home/clients",
    exact: true,
    name: "Clients",
    component: Clients,
  },
  {
    path: "/home/clients/:id",
    exact: true,
    name: "Détails client",
    component: Client,
  },
  {
    // path: "/home/dashboardAdministrateur", // If I comment this out, the home page becomes this and still the navbar works!
    path: "/home/dashboard",
    exact: true,
    name: "Analytics",
    component: DashboardAdministrateur,
  },
];
const operateur_tel_routes = [
  { path: "/home", exact: true, name: "Accueil" },
  {
    path: "/home/configurationCompte",
    name: "Configuration",
    component: Settings,
  },
  { path: "/home/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/home/operationSav/:id",
    exact: true,
    name: "Opération SAV",
    component: OperationSavDetails,
  },
  {
    path: "/home/mesOperationsSav",
    exact: true,
    name: "Mes opérations SAV",
    component: MesOperationsSav,
  },
  {
    path: "/home/historiqueDesOperationsSAv",
    exact: true,
    name: "Historique des opérations SAV",
    component: HistoriqueDesOperationSav,
  },
  {
    path: "/home/operationSavCreation",
    exact: true,
    name: "Créer une opération SAV",
    component: OperationSavCreation,
  },

  {
    path: "/home/clients",
    exact: true,
    name: "Clients",
    component: Clients,
  },
  {
    path: "/home/clients/:id",
    exact: true,
    name: "Détails client",
    component: Client,
  },
];
const technicien_routes = [
  { path: "/home", exact: true, name: "Accueil" },
  {
    path: "/home/configurationCompte",
    name: "Configuration",
    component: Settings,
  },
  { path: "/home/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/home/produits", name: "Produits", component: Produits },
  { path: "/home/composants", name: "Composants", component: Composants },
  {
    path: "/home/composant/:id",
    exact: true,
    name: "Détails composant",
    component: ComposantDetails,
  },

  {
    path: "/home/operationSav/:id",
    exact: true,
    name: "Opération SAV",
    component: OperationSavDetails,
  },
  {
    path: "/home/operationsSavEnAttentePourTechnicien",
    exact: true,
    name: "Opérations SAV en attente",
    component: OperationsSavEnAttentePourTechnicien,
  },
  {
    path: "/home/mesOperationsSav",
    exact: true,
    name: "Mes opérations SAV",
    component: MesOperationsSav,
  },
  {
    path: "/home/historiqueDesOperationsSAv",
    exact: true,
    name: "Historique des opérations SAV",
    component: HistoriqueDesOperationSav,
  },
  {
    path: "/home/produit/:id",
    exact: true,
    name: "Produit",
    component: ProduitDetails,
  },
];
const revendeur_routes = [
  { path: "/home", exact: true, name: "Accueil" },
  {
    path: "/home/configurationCompte",
    name: "Configuration",
    component: Settings,
  },
  { path: "/home/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/home/users", exact: true, name: "Utilisateurs", component: Users },
  {
    path: "/home/operationSav/:id",
    exact: true,
    name: "Opération SAV",
    component: OperationSavDetails,
  },

  {
    path: "/home/historiqueDesOperationsSAv",
    exact: true,
    name: "Historique des opérations SAV",
    component: HistoriqueDesOperationSav,
  },
  {
    path: "/home/operationSavCreation",
    exact: true,
    name: "Créer une opération SAV",
    component: OperationSavCreation,
  },

  {
    path: "/home/clients",
    exact: true,
    name: "Clients",
    component: Clients,
  },
  {
    path: "/home/clients/:id",
    exact: true,
    name: "Détails client",
    component: Client,
  },
];
const logisticien_routes = [
  { path: "/home", exact: true, name: "Accueil" },
  {
    path: "/home/configurationCompte",
    name: "Configuration",
    component: Settings,
  },
  { path: "/home/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/home/users", exact: true, name: "Utilisateurs", component: Users },
  {
    path: "/home/operationSav/:id",
    exact: true,
    name: "Opération SAV",
    component: OperationSavDetails,
  },
  {
    path: "/home/mesOperationsSav",
    exact: true,
    name: "Mes opérations SAV",
    component: MesOperationsSav,
  },
  {
    path: "/home/historiqueDesOperationsSAv",
    exact: true,
    name: "Historique des opérations SAV",
    component: HistoriqueDesOperationSav,
  },
];
const magasinier_routes = [
  { path: "/home", exact: true, name: "Accueil" },
  {
    path: "/home/configurationCompte",
    name: "Configuration",
    component: Settings,
  },
  { path: "/home/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/home/produits", name: "Produits", component: Produits },
  { path: "/home/composants", name: "Composants", component: Composants },
  {
    path: "/home/composant/:id",
    exact: true,
    name: "Détails composant",
    component: ComposantDetails,
  },

  {
    path: "/home/operationSav/:id",
    exact: true,
    name: "Opération SAV",
    component: OperationSavDetails,
  },

  {
    path: "/home/mesOperationsSav",
    exact: true,
    name: "Mes opérations SAV",
    component: MesOperationsSav,
  },
  {
    path: "/home/historiqueDesOperationsSAv",
    exact: true,
    name: "Historique des opérations SAV",
    component: HistoriqueDesOperationSav,
  },

  {
    path: "/home/produit/:id",
    exact: true,
    name: "Produit",
    component: ProduitDetails,
  },
];

export {
  operateur_tel_routes,
  technicien_routes,
  revendeur_routes,
  logisticien_routes,
  magasinier_routes,
};

export default routes;
