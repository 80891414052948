// What we will do here will prevent us from manually making sure of having the token inside each relevant request
// If we're logged-in, we can call this function and it will always attach that authorization header
// TODO: Need to make sure that the token is stored inside x-access-token
import axios from "axios";
import API from "../conf/api";
const setAuthToken = token => {
  console.log("setAuthToken is called");
  if (token) {
    // Apply to every request
    // Setting-up the authentication header in the axios singleton instance before the instance gets created
    // Because otherwise, the requests will be sent without the authentication header
    // 1. Set authentication header
    // 2. Axios instance gets created with authentication header when it's called in Users.js for example
    API.defaults.headers.common = {
      "x-access-token": token
    };
  } else {
    // Delete the Auth header
    API.defaults.headers.common = {
      // Authorization: token,
      //TODO: Not sure about this
      "x-access-token": token
    };
  }
};
export default setAuthToken;
