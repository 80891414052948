import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { config } from "./conf/config";

const initialState = {};
const middleware = [thunk];

// const store =
//   config.production === false
//     ? createStore(
//         rootReducer,
//         initialState,
//         compose(
//           applyMiddleware(...middleware),
//           window.__REDUX_DEVTOOLS_EXTENSION__ &&
//             window.__REDUX_DEVTOOLS_EXTENSION__()
//         )
//       )
//     : createStore(
//         rootReducer,
//         initialState,
//         compose(applyMiddleware(...middleware))
//       );

let store;
if (config.dev_local == true) {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  );
}

export default store;
