import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import API from "../conf/api";
// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  API.post("api/admin/login", userData)
    .then((res) => {
      console.log("inside POST http://vps473420.ovh.net:3083/api/admin/login");
      console.log("res.data.admin", res.data.admin);

      // Save to localStorage
      const token = res.data.admin.token;
      // console.log("token", res.data.admin.token);

      // Set token to ls
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      // TODO: I believe this should be changed
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);
      console.log("decoded token", decoded);

      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      console.log("inside err");
      console.log(err);
      console.log("err.response.data");
      dispatch({
        type: GET_ERRORS,
        // TODO: Fix the error object that is sent in the backend
        // The error that is sent should already be in the format of an object
        payload: { errors: err.response.data },
        // payload: err.response.data
      });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const passwordForgotten = (userEmail) => {
  return API.post("api/admin/passwordForgotten", {
    email: userEmail,
  });
};
export const checkIfResetPasswordTokenExists = (tokenInfo) => {
  return API.get("api/admin/checkIfResetPasswordTokenExists", {
    params: tokenInfo,
  });
};
export const reset_password = (tokenInfo) => {
  return API.post("api/admin/reset_password", tokenInfo);
};
export const change_password_when_logged_in = (password_info) => {
  return API.post("api/admin/change_password_when_logged_in", password_info);
};
