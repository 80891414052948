import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

// react-redux
import { Provider } from "react-redux";
import store from "./store";

// This is to keep the user logged-in once he has logged-in
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import PrivateRoute from "./common/PrivateRoute";

// TODELETE: This is just to test
import routes from "./routes";
const Users = React.lazy(() => import("./views/Users/Users"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const PasswordResetPage = React.lazy(() =>
  import("./views/Pages/PasswordResetPage/PasswordResetPage")
);
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
// If the user has already logged-in so the token is stored in localStorage and he reloads the page
// This will restore the token data in the state
// So the reducer will set isAuthenticated as true
// So all the components will behave as if the user is authenticated
// And hence the user will find himself still logged-in and will not have to redo the login cycle
// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  // Decode token and get user info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set User and is Authenticated
  store.dispatch(setCurrentUser(decoded));
  // Now if you reload a page, if the user has already logged-in you will still have his info in he state

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout User
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              {/* http://localhost:4066/#/reset_password */}
              {/* I don't understand why I need the hashtag */}
              <Route
                exact
                path="/reset_password_page/:resetPasswordToken"
                name="Login Page"
                render={(props) => <PasswordResetPage {...props} />}
              />
              {/* http://localhost:4066/#/404 */}
              {/* I don't understand why I need the hashtag */}
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
            </Switch>

            <Switch>
              {/* MUST NOT ADD THE EXACT ATTRIBUTE. Otherwise, the router will not pass to the next nestedRoutes */}
              <PrivateRoute
                name="Home"
                path="/home"
                component={DefaultLayout}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
